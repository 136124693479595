<template>
	<div class="flex flex-wrap">
		<div class="w-full py-3 text-center uppercase">
			or continue with
		</div>
		<div class="flex w-full space-x-2">
			<div class="w-1/3">
				<a
					href="#"
					class="flex justify-center border rounded p-2.5 h-12"
					@click="handleGoogleLogin"
				>
					<img
						src="@/assets/icons/google.svg"
						class="w-6 h-6"
						alt="Google"
					>
				</a>
			</div>
			<div class="w-1/3">
				<VFacebookLogin
					v-model="facebook"
					class="w-full h-12 text-black"
					:logo-style="{
						'margin-right': '0px'
					}"
					:app-id="FACEBOOK_APP_ID"
					@sdk-init="handleSdkInit"
					@login="handleFacebookLogin"
				>
					<template #login>
						{{ loginText }}
					</template>

					<template #logout>
						{{ logoutText }}
					</template>
				</VFacebookLogin>
			</div>
			<div class="w-1/3">
				<a
					href="#"
					class="flex justify-center rounded bg-twitter-blue p-2.5 h-12"
					@click="handleTwitterLogin"
				>
					<img
						src="@/assets/icons/twitter-white.svg"
						class="w-6 h-6"
						alt="Twitter"
					>
				</a>
			</div>
		</div>
	</div>
</template>

<script async>
import VFacebookLogin from 'vue-facebook-login-component'
import { mapActions, mapGetters } from 'vuex'

import { API_SERVER, FACEBOOK_APP_ID } from '@/constants/index.js'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		VFacebookLogin
	},
	emits: [ 'logged-in' ],
	data() {
		return {
			API_SERVER,
			FACEBOOK_APP_ID,
			loginText: '',
			logoutText: 'Log Out',
			twitterUrl: `${API_SERVER}/login_twitter?return=close`,
			googleUrl: `${API_SERVER}/login_google?return=close`,
			facebook: {},
			FB: {},
			facebookScope: {}
		}
	},
	computed: {
		...mapGetters('auth', [ 'isLoggedIn' ])
	},
	watch: {
		'$store.state.auth.loggedIn': function () {
			if (this.$store.state.auth.loggedIn) {
				this.$emit('logged-in')
			}
		},
		facebookScope() {
			if (!this.facebookScope.connected) {
				this.facebookScope.logout()
			}
		}
	},
	methods: {
		...mapActions('auth', [
			'loginWithFacebook'
		]),
		...mapActions([ 'newGet' ]),
		handleTwitterLogin() {
			const twitterWindow = window.open(this.twitterUrl, 'blank')
			const twitterWindowTimer = setInterval(() => {
				if (twitterWindow.closed) {
					clearInterval(twitterWindowTimer)
					this.checkAuth()
				}
			}, 300)
		},
		handleGoogleLogin() {
			const googleWindow = window.open(this.googleUrl, 'blank')
			const googleWindowTimer = setInterval(() => {
				try {
					if (googleWindow.closed) {
						clearInterval(googleWindowTimer)
						this.checkAuth()
					}
				} catch (e) {
					clearInterval(googleWindowTimer)
					logError(e)
				}
			}, 300)
		},
		async handleFacebookLogin(response) {
			await response
			if (response) {
				this.loginWithFacebook(response)
			}
		},
		async checkAuth() {
			try {
				const endpoint = 'info'
				await this.newGet({ endpoint })
				if (this.isLoggedIn !== true) {
					throw new Error('Authentication failed')
				}
			} catch (e) {
				logError(e)
			}
		},
		handleSdkInit({ FB, scope }) {
			this.FB = FB
			this.facebookScope = scope
		}
	}
}
</script>

<style scoped>
.bg-twitter-blue {
	border: 1px solid #03a9f4;
	background-color: #03a9f4;
}
</style>
